/* eslint-disable no-unused-vars */
/* eslint-disable import/no-anonymous-default-export */
import React, { Fragment, useEffect, useState, useContext } from "react";
import "./style.css";
import "./grid.css";
import { connect } from "react-redux";
import FeedbackContext from "../../context/cmscontext";
import { scrollTop } from "../../utils/window";
import { PRODUCT_LIST } from "../../constant";
import { GET } from "../../constant/apiMethods";
import Labels from "../../services/apiLabels";

import { BsListUl, BsList, BsFillGridFill, BsGrid } from "react-icons/bs";
import { FaThList, FaFilter } from "react-icons/fa";
import { PaginationControl } from "react-bootstrap-pagination-control";

import Drawer from "../../components/Drawer";
import ProductsListView from "../../components/Product list/Products";
import ProductsGridView from "../../components/Product grid/Products";
import Auctionlist from "../../components/Acution Listing/Filters";
import AuctionWon from "../../components/Auction Won/Auctionwon";

function AuctionDashboard(props) {
  const {
    product,
    getProductList,
    filtersQuery,
    navbarDimensions,
    user_id,
    wishList,
  } = props;
  const { data } = product;
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [page, setPage] = useState(product?.data?.current_page_no ?? 1);
  const [showgrid, setShowgrid] = useState(false);
  const [sortValue, setSortValue] = useState("1"); // Default to Price: Low - High
  const { feedback, isLoading } = useContext(FeedbackContext);
  let auction_page = feedback.auction_page;

  console.log("Data\n", data);

  let selectedFilter = React.useRef({
    page: 1,
    page_size: 10,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    sort_cars: sortValue, // Add sort_cars to the filter
  });

  selectedFilter.current["page"] = page;
  let queryString = Object.keys(selectedFilter.current)
    .map((key) => key + "=" + selectedFilter.current[key])
    .join("&");

  const handleDrawer = () => {
    setMobileDrawer(!mobileDrawer);
  };

  useEffect(() => {
    if (wishList) {
      getProductList(queryString);
    }
  }, [wishList]);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    selectedFilter.current.sort_cars = sortValue;
    let queryString = Object.keys(selectedFilter.current)
      .map((key) => key + "=" + encodeURIComponent(selectedFilter.current[key]))
      .join("&");

    getProductList(queryString);
  }, [sortValue]);

  console.log("filtersQuery\n", filtersQuery);
  console.log("selectedFilter\n", selectedFilter);
  
  const setAuctionStatus = async (status) => {
    if (status === "All Auction") {
      selectedFilter.current.auction_status = "Upcoming Auction,Live Auction";
    } else {
      selectedFilter.current.auction_status = status;
    }
    selectedFilter.current.page = 1;
    let queryString = Object.keys(selectedFilter.current)
      .map((key) => key + "=" + encodeURIComponent(selectedFilter.current[key]))
      .join("&");

    console.log(queryString);

    const filter = await getProductList(queryString);
    console.log("filter\n", filter);
  };
  
  const isSelected = (status) =>
    selectedFilter?.current?.auction_status === status || 
    (status === "All Auction" && selectedFilter?.current?.auction_status === "Upcoming Auction,Live Auction");

  const handleSortChange = (event) => {
    setSortValue(event.target.value);
  };

  return (
    <Fragment>
      <div className="d-flex row-12 landing_page_main_cont2">
        <div className="col-2 landing_page_left_cont_wrapper">
          <div className="mt-1">
            <Auctionlist 
            isBuyNow={false} 
            handleDrawer={handleDrawer}
            setPage={setPage}
            selectedFilter={selectedFilter} />
          </div>
        </div>

        <div className="col-md-7 col-12 product-list-cont">
          <div className="buttons_tab_main_cont">
            <div className="mobile_filter_drawer_wrapper">
              <button
                onClick={handleDrawer}
                className="mobile_filter_drawer_button"
              >
                Filter <FaFilter style={{ fontSize: "0.9rem" }} />
              </button>
              <Drawer
                visible={mobileDrawer}
                handleDrawer={handleDrawer}
                body={
                  <Auctionlist
                    handleDrawer={handleDrawer}
                    setPage={setPage}
                    selectedFilter={selectedFilter}
                  />
                }
                placement="left"
              />
            </div>

            <div className="grid_list_toggle_button_group">
              <div className="upper-buttons-class">
                {["All Auction", "Live Auction", "Upcoming Auction"].map(
                  (status) => (
                    <button
                      key={status}
                      className={
                        isSelected(status)
                          ? "selected-option-class"
                          : "not-selected-option-class"
                      }
                      onClick={() => setAuctionStatus(status)}
                    >
                      {status}
                    </button>
                  )
                )}
              </div>
              <div className="sorting-buttons-class">
                <button
                  style={{
                    backgroundColor: showgrid
                      ? "var(--secondary)"
                      : "var(--primary)",
                  }}
                  onClick={() => setShowgrid(false)}
                  className="grid_list_toggle_button "
                >
                  <FaThList
                    style={{ fontSize: "1.1rem" }}
                    className="grid_list_toggle_button_icon"
                  />

                  {showgrid ? null : (
                    <span style={{ marginLeft: 5 }}>List</span>
                  )}
                </button>

                <button
                  style={{
                    backgroundColor: showgrid
                      ? "var(--primary)"
                      : "var(--secondary)",
                  }}
                  onClick={() => setShowgrid(true)}
                  className="grid_list_toggle_button "
                >
                  <BsFillGridFill
                    style={{ fontSize: "1.2rem" }}
                    className="grid_list_toggle_button_icon"
                  />
                  {showgrid ? (
                    <span style={{ marginLeft: 5 }}>Grid</span>
                  ) : null}
                </button>

                <div className="sort d-flex align-items-center justify-content-center float-left">
                  <label className="m-0 me-2" htmlFor="sort_cars">
                    Sort By:
                  </label>

                  <select className="selectform w-50" id="sort_cars" name="sort_cars" value={sortValue} onChange={handleSortChange}>
                    <option value="1">Price: Low - High</option>
                    <option value="2">Price: High - Low</option>
                    <option value="3">Mileage: Low - High</option>
                    <option value="4">Mileage: High - Low</option>
                    <option value="5">Make: A - Z</option>
                    <option value="6">Make: Z - A</option>
                    <option value="7">Ending Next</option>
                    <option value="8">Ending Last</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {showgrid ? (
            <ProductsGridView loading={product.fetchingList} data={data} />
          ) : (
            <ProductsListView loading={product.fetchingList} data={data} />
          )}
          <div>
            {!product.fetchingList &&
              product?.data?.data &&
              product?.data?.data?.length > 0 && (
                <>
                  <button className="side_bar_header_title_2 ">
                    Showing{" "}
                    {product?.data?.current_page_no *
                      selectedFilter.current["page_size"] -
                      selectedFilter.current["page_size"] +
                      1}{" "}
                    -{" "}
                    {product?.data?.current_page_no *
                      selectedFilter.current["page_size"] <
                    product?.data?.total_object
                      ? product?.data?.current_page_no *
                        selectedFilter.current["page_size"]
                      : product?.data?.total_object}{" "}
                    of {product?.data?.total_object} Results
                  </button>
                </>
              )}
          </div>
          {product.data.num_of_pages > 1 && (
            <>
              <div className="mx-auto">
                <PaginationControl
                  page={page}
                  between={4}
                  total={product.data.total_object}
                  limit={selectedFilter.current.page_size}
                  changePage={async (page) => {
                    setPage(page);
                    selectedFilter.current["page"] = page;
                    let queryString = Object.keys(selectedFilter.current)
                      .map((key) => key + "=" + selectedFilter.current[key])
                      .join("&");
                    console.log("queryString\n", queryString);
                    const filter = await getProductList(queryString);
                    console.log("filter\n", filter);
                  }}
                  ellipsis={2}
                />
              </div>
            </>
          )}
        </div>

        <div className="col-3 landing_page_right_cont_wrapper">
          <div class="mt-1">
            <label className="mt-2" htmlFor="page_limit">
              Display:
            </label>
            <select className="selectform" id="page_limit">
              <option value="10" id="page_count">
                10
              </option>
              <option value="25" selected="" id="page_count">
                25
              </option>
              <option value="50" id="page_count">
                50
              </option>
              <option value="100" id="page_count">
                100
              </option>
            </select>
          </div>

          <div
            className="landing_page_right_cont"
            style={{
              right: 0,
              height: `calc(100vh - ${navbarDimensions.height}px)`,
            }}
          >
            <AuctionWon />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  product: state.product,
  filtersQuery: state.filters.queryString_,
});

const mapDispatchToProps = (dispatch) => ({
  getProductList: (params) =>
    dispatch({
      type: PRODUCT_LIST,
      payload: {
        type: PRODUCT_LIST,
        method: GET,
        label: `${Labels.product_list}?${params}`,
        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDashboard);
