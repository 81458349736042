/* eslint-disable import/no-anonymous-default-export */
export default {
  //baseUrl: "http://192.168.18.36:8080/",
  // baseUrl: "http://  192.168.18.9:8000/",
  baseUrl: "https://backend2.sellanywheel.com/",
  //baseUrl : "http://192.168.18.36:8000/"
  //baseUrl: "https://pa3ge.localto.net/"


 
  
  
  
  
};
