import React from 'react';

const VehicleDetailsGrid = ({ title, bg,details,comments }) => {

  return (
    <div className="container-fluid" style={{background:`${bg ? "#f5f5f5":"white"}`}}>
      <div className="row">
        <h4 className='ms-md-3 my-lg-4 my-3'>{title}</h4>
        {details && details.map((key, index) => (
          <div key={index} className="col-md-6 col-lg-4 mb-3" >
            <div className="card border-0" style={{background:`${bg ? "#f5f5f5":"white"}`}}>
              <div className="card-body d-flex align-items-center justify-content-between">
                <h6 className="card-title fw-bold">{key.name}</h6>
                <p className="card-text">{key.value ? key.value === true ? "Yes":key.value : "false"}</p>
              </div>
            </div>
          </div>
        ))}
        {comments &&
          <div className="mb-3" >
            <div className="card border-0" style={{background:`${bg ? "#f5f5f5":"white"}`}}>
              <div className="card-body d-flex align-items-center justify-content-between">
                <p className="card-text">{comments}</p>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default VehicleDetailsGrid;
