import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import "./buyModal.css";
import { toast } from 'react-toastify';
import { TOASTER_SHOW, toaster_s, toaster_e, toaster_w } from "../../constant";
export const CarModal = ({ show, handleClose, car }) => {
  const { id, main_image, product_name, product_price, shippingCharges } = car;
  const userId = useSelector((state) => state?.auth?.id);
  const [postcode, setPostcode] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [comments, setComments] = useState("");
  const dispatch = useDispatch()
  const handleSubmit = async () => {
    const endPoint = `${config.baseUrl}api/v1/insertorder/`;
    const requestBody = {
      user: userId,
      product: id,
      description: "Shipment of a new laptop",
      postcode: postcode,
      shipping_address: shippingAddress,
      shipment_type: "1", 
      no_of_cars: 1
    };

    try {
      const response = await axios.post(endPoint, requestBody);
      console.log(response.data.message.type)
      if(response.data.message.type === "success"){
        handleClose()
        dispatch({
        type: TOASTER_SHOW,
        payload: {
          show: true,
          type: toaster_s,
          description: "Order submitted successfully!",
        },
      });
      setTimeout(() => {
        window.location.href = "/buynow";
      }, 1000);
    }else{
      dispatch({
      type: TOASTER_SHOW,
      payload: {
        show: true,
        type: toaster_e,
        description: "There was an issue! Please try again",
      },
    });
      
    }
        } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      centered
      dialogClassName="custom-modal"
    >
      <Modal.Header closeButton style={{ background: "#178971" }}>
        <Modal.Title>
          <h3 className="text-white fs-3">Confirm Details</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex">
          <img
            src={main_image}
            alt={product_name}
            className="img-fluid mb-3"
            style={{ height: "70px", width: "auto" }}
          />
          <div className="ms-md-4 ms-2 text-center d-flex flex-column align-items-start justify-content-start">
            <span className="fs-4 fw-bold mb-2">{product_name}</span>
            <span className="">
              Price: <span className="fw-bold">${product_price}</span>
            </span>
            <span className="">
              Shipping Charges:{" "}
              <span className="fw-bold">${shippingCharges}</span>
            </span>
          </div>
        </div>
        <Form>
          <div className="container-fluid mt-md-3 mt-2">
            <div className="row mb-2">
              <div className="col-md-6">
                <Form.Group controlId="formPostcode">
                  <Form.Label>Post Code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Postcode"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group controlId="forshipping_address">
                  <Form.Label>Shipping Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Shipping Address"
                    value={shippingAddress}
                    onChange={(e) => setShippingAddress(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="row mb-2">
              <Form.Group controlId="formComments">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Enter comments"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <button className="button_heder rounded w-100 me-3 px-3" onClick={handleClose}>
            Close
          </button>
          <button
            className="btn_contactv"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
