import React, { useState, useEffect } from "react";
import "./AddWheel.css";
import CarDetailForm from "../CarDetailForm";
import { CAR_REG_NUMBER, SELL_MY_CAR_STEPS, ADD_VEHICLE } from "../../constant";
import { GET, POST, PATCH } from "../../constant/apiMethods";
import { connect } from "react-redux";
import { useContext } from "react";
import Labels from "../../services/apiLabels";
import config from "../../config";
import FeedbackContext from "../../context/cmscontext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import number_logo from "../../assets/images/logo2.png";
import { AiFillCaretRight } from "react-icons/ai";
import { Spinner } from "react-bootstrap";

export const API_ENDPOINT = (label) => {
  return `${config.baseUrl}${label}`;
};

const AddWheel = ({
  user_id,
  getAllCars,
  setSelectedTabIdx,
  isEdit,
  setisEdit,
  car_reg_num,
  sellMyCar,
  car_reg_loading,
  reducer,
  setbrand,
  setmodel,
  setvariant,
  sellMyCar_new,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    postcode: "",
    price: "",
    vat:"",
    registration_number: "",
    millage: "",
    description: "",
  });
  const [carStepsData, setcarStepsData] = useState({});
  const [category, setCategory] = useState(1);
  const [bodytype, setBodytype] = useState(1);
  const [transmission, setTransmission] = useState(1);
  const [regunm, setregunm] = useState();
  const [show, setshow] = useState(false);
  const generateYears = (start, end) => {
    let years = [];
    for (let i = start; i <= end; i++) {
      years.push(i);
    }
    return years;
  };

  const years = generateYears(1995, 2030);
  console.log("sellMyCar_new --->>>", sellMyCar_new);

  const { feedback, isLoading } = useContext(FeedbackContext);
  var home_page = feedback.home_page;

  const car_value = (e) => {
    e.preventDefault();
    // setisEdit(true)
    setshow(true);
    car_reg_num({
      Reg_Num: regunm,
    });
  };

  const [carData, setcarData] = useState({
    brands: "",
    model_name: "",
    fuel_type: "",
    year: "",
  });

  useEffect(() => {
    if (isEdit) {
      const payload = {
        type: ADD_VEHICLE,
        label: `${Labels.delvechiel}${isEdit}/`,
        method: POST,

        hideOverlayLoader: true,
      };

      getSingleData(payload);
    }
  }, [isEdit]);

  useEffect(() => {
    if (!isEdit) {
      // setcarStepsData({});
    }
  }, []);
  const [preFillData, setPreFillData] = useState('');
  useEffect(() => {
    if (sellMyCar_new?.brands) {
      setshow(false);
      setFormData({
        name: sellMyCar_new?.variant?.display || "",
        postcode: sellMyCar_new.postcode || "",
        price: sellMyCar_new.price || "",
        vat: sellMyCar_new.vat || "",
        registration_number: regunm || "",
        millage: sellMyCar_new.millage || "",
        description: sellMyCar_new.description || "",
      });
      setCategory(sellMyCar_new.category || 1);
      setBodytype(sellMyCar_new.bodytype || 1);
      setTransmission(sellMyCar_new.transmission || 1);
      setcarStepsData(sellMyCar_new);
    }
  }, [sellMyCar_new]);

  const notify = (masg) =>
    toast.error(`${masg}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const readURL = (file) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = (e) => res(e.target.result);
      reader.onerror = (e) => rej(e);
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    let file = e.target.files[0];
    const url = await readURL(file);
  };

  const updateData = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (carData.brands != "" && carData.model_name != "") {
      let data = new FormData();
      data.append("name", formData.name);
      data.append("category", category);
      data.append("postcode", formData.postcode);
      data.append("registration_number", formData.registration_number);
      data.append("bodytype", bodytype);
      data.append("transmission", transmission);
      data.append("millage", formData.millage);
      data.append("vat", formData.vat);
      data.append("price", formData.price);
      data.append("description", formData.description);
      data.append("brands", carData.brands);
      data.append("model_name", carData.model_name);
      data.append("fuel_type", carData.fuel_type);
      data.append("year", carData.year);
      data.append("created_by", user_id);
      if (isEdit) {
        const payload = {
          type: ADD_VEHICLE,
          data: data,
          label: `${Labels.delvechiel}${isEdit}/`,
          method: PATCH,

          hideOverlayLoader: true,
        };
        updatecarData(payload);
      } else {
        const payload = {
          type: ADD_VEHICLE,
          data: data,
          label: `${Labels.addvechiel}`,
          method: POST,

          hideOverlayLoader: true,
        };
        fetchData(payload);
      }
    } else {
      const masg = `${carData.brands == "" ? "Brand, " : ""}  ${
        carData.model_name == "" ? "Model ," : ""
      }  requried`;
      notify(masg);
    }
  };

  function updatecarData(payload) {
    const { label, data, method, header } = payload;

    const parameters = {
      method,
      body: data,
    };
    fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        getAllCars();
        setisEdit(null);
        setSelectedTabIdx(0);
        resetFormData();
      })
      .then((json) => {
        return json;
      });
  }

  function fetchData(payload) {
    const { label, data, method, header } = payload;

    const parameters = {
      method,
      body: data,
    };
    fetch(API_ENDPOINT(label), parameters)
      .then((response) => {
        getAllCars();
        setSelectedTabIdx(0);
        resetFormData()
      })
      .then((json) => {
        return json;
      });
  }

  function getSingleData(payload, countryCountyPayload) {
    const { label, data, method, header } = payload;

    const parameters = {
      GET,
      body: data,
    };
    fetch(API_ENDPOINT(label), parameters)
      .then((response) => response.json())
      .then((data) => {
        formData.name = data.data.name;
        setCategory(data.data.category);
        formData.description = data.data.description;
        formData.price = data.data.price;
        formData.vat = data.data.vat;
        formData.registration_number = data.data.registration_number;
        setBodytype(data.data.bodytype) ;
        formData.postcode = data.data.postcode;
        carData.fuel_type = data.data.fuel_type.id;
        carData.model_name = data.data.model_name.id;
        setTransmission(data.data.transmission);
        carData.millage = data.data.millage;
        // setcarData({ ...carData });
        setFormData({ ...formData });
        setcarStepsData(data.data);
      });
  }
  const resetFormData = () => {
    console.log("----------------------------------")
    setFormData({
      name: "",
      postcode: "",
      price: "",
      vat: "",
      registration_number: "",
      millage: "",
      description: "",
    });
    setCategory(1);
    setBodytype(1);
    setTransmission(1);
    setcarData({
      brands: "",
      model_name: "",
      fuel_type: "",
      year: "",
    });
    setcarStepsData({})
    window.location.reload()
  };
  
  return (
    <div>
      <div className="reg_car_wrap_2">
        <form onSubmit={car_value} autoComplete="off">
          <div className="input_main_wrap_2 d-flex">
            <img className="img_d2 w-auto" src={number_logo} alt="" />
            <input
              className="reg_input"
              required
              type="text"
              onChange={(e) => {
                setregunm(e.target.value.toUpperCase());
              }}
              placeholder={home_page.car_reg_placeholder}
            />
            <button className="value_btn" name="CTAButton">
              {show ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                <AiFillCaretRight />
              )}
            </button>
          </div>
        </form>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-6 form-group">
            <label htmlFor="name-f">Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              id="name-f"
              value={formData.name}
              placeholder="Enter Your Name"
              required
              onChange={updateData}
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="name-l">Category</label>
            <select
              className="form-control p-1"
              aria-label="Default select example"
              name="category"
              defaultValue={1}
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
            >
              <option value={2}>New</option>
              <option value={3}>Used</option>
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="Postcode">Post Code</label>
            <input
              type="text"
              className="form-control"
              name="postcode"
              id="postcode"
              value={formData.postcode}
              placeholder="Enter Your Post Code"
              onChange={updateData}
              required
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control"
              name="price"
              id="price"
              value={formData.price}
              placeholder="Enter Price"
              onChange={updateData}
              required
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="vat">VAT</label>
            <input
              type="number"
              className="form-control"
              name="vat"
              id="vat"
              value={formData.vat}
              placeholder="Enter VAT"
              onChange={updateData}
              required
            />
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="RegistrationNumber">Registration Number</label>
            <input
              type="text"
              className="form-control"
              name="registration_number"
              id="registration_number"
              value={formData.registration_number}
              disabled={sellMyCar_new.api_data}
              placeholder="Enter Your Registration Number"
              onChange={updateData}
              required
            />
          </div>
          <CarDetailForm
            isEdit={isEdit}
            carStepsData={carStepsData}
            carData={carData}
            sellMyCar_new={sellMyCar_new}
            setcarData={setcarData}
            preFillData={preFillData}
            reducer="sellMyCar"
            reduxType={"SELL_MY_CAR_STEPS"}
          />
                    <div className="col-sm-6 form-group">
            <label htmlFor="bodytype">Body Type</label>
            <select
              className="form-control p-1"
              aria-label="Default select example"
              name="bodytype"
              defaultValue={1}
              value={bodytype}
              onChange={(e) => setBodytype(e.target.value)}
              required
            >
              <option value={1}>Hatchback</option>
              <option value={2}>MPV</option>
              <option value={3}>SUV</option>
              <option value={4}>Saloon</option>
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="transmission">Transmission</label>
            <select
              className="form-control p-1"
              aria-label="Default select example"
              name="transmission"
              defaultValue={1}
              value={transmission}
              onChange={(e) => setTransmission(e.target.value)}
              required
            >
              <option value={1}>Auto</option>
              <option value={2}>Manual</option>
              <option value={3}>Both</option>
            </select>
          </div>
          <div className="col-sm-6 form-group">
            <label htmlFor="millage">Mileage</label>
            <input
              type="text"
              className="form-control"
              name="millage"
              id="millage"
              defaultValue={1}
              value={formData.millage}
              placeholder="Enter Mileage"
              onChange={updateData}
              required
            />
          </div>
          <div
            className="form-floating col-sm-6 form-group"
            style={{ marginTop: 24 }}
          >
            <textarea
              style={{ minHeight: "170px" }}
              className="form-control"
              placeholder="Leave a comment here"
              id="description"
              name="description"
              value={formData.description}
              onChange={updateData}
            ></textarea>
            <label for="floatingTextarea" className="pl-4">
              Description
            </label>
          </div>
          {/* <div className=" col-sm-6 mb-3">
              <div className="row">
                <div className="col-6">
                <label for="formFileMultiple" className="form-label">
                Image
              </label>
              <input
                className="form-control"
                id="formFileMultiple"
                type='file'
                name="image"
                multiple
                onChange={(e)=>handleImageChange(e)}
              />
                </div>
                <div className="col-6">
                  {imageurl && (
                    <img src={imageurl}></img>
                  )}
                  
                </div>
              </div>
            

            </div> */}
          {/* <div className="form-check col-sm-4" style={{paddingLeft:35}}>
              <input
                className="form-check-input bg-white"
                type="checkbox"
                defaultValue='off'
                id="flexCheckDefault"
                name="Status"
                onChange={updateData}
              />
              <label className="form-check-label pt-1 pl-2" for="flexCheckDefault">
                Status
              </label>
            </div>
            <div className="form-check col-sm-4" style={{paddingLeft:35}}>
              <input
                className="form-check-input bg-white"
                type="checkbox"
                defaultValue='off'
                id="flexCheckDefault"
                name="BuyNow"
                onChange={updateData}
              />
              <label className="form-check-label pt-1 pl-2" for="flexCheckDefault">
                Buy Now
              </label>
            </div> */}
          {/* <div className="col-sm-4 form-group pt-1">
            <label htmlFor="price"> Buy Now Price</label>
            <input
              type="number"
              className="form-control "
              name="BuyNowPrice"
              id="Price"
              placeholder="Enter Price"
              required
              onChange={updateData}

            />
          </div> */}
          <div className="col-sm-12 form-group mb-3">
            <button
              className=" w-25 btn_contacta float-right border-radius-2"
              type="submit"
            >
              {isEdit ? "Update" : "Submit"}{" "}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Same as */}
      <ToastContainer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user_id: state.auth.id,
  myVehiclesList: state?.myVehicles?.data,
  sellMyCar_new: state.sellMyCar.car_reg_,
  car_reg_loading: state.sellMyCar.car_reg_loading,

  fetchingBrands: state.sellMyCar.fetchingBrands,
  brands: state.sellMyCar.brands,
  //
  showInterest: state.showInterest,
  sellMyCar: state.sellMyCar,
});

const mapDispatchToProps = (dispatch) => ({
  car_reg_num: (data) =>
    dispatch({
      type: CAR_REG_NUMBER,
      payload: {
        type: CAR_REG_NUMBER,
        method: POST,
        label: Labels.car_reg_chk,
        hideOverlayLoader: true,
        data,
      },
    }),

  setbrand: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "brand",
        data,
      },
    }),
  setmodel: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "model",
        data,
      },
    }),
  setfuelType: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "fuel_type",
        data,
      },
    }),
  setyear: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "year",
        data,
      },
    }),
  setvariant: (data) =>
    dispatch({
      type: SELL_MY_CAR_STEPS,
      payload: {
        type: "variant",
        data,
      },
    }),

  addvechiel: (data) =>
    dispatch({
      type: ADD_VEHICLE,
      payload: {
        type: ADD_VEHICLE,
        data,
        label: `${Labels.addvechiel}`,
        method: POST,

        hideOverlayLoader: true,
      },
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddWheel);